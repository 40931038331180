import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class HeartDiseaseHome extends React.Component {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(0);
    }

    render(){
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="HeartDiseaseMainContainer">
                        <div className="ContentContainer">
                            <div className="Content ContentHalfPadding">
                                <div className="SectionContainer">
                                    <p className="SectionText">Learning that your dog has heart disease can be concerning. It is important to remember, that with the care of you and your vet, your dog can live a long and happy life.</p>
                                    <p className="SectionText HighlightedSection">Heart disease affects 10% of all dogs.<sup>1</sup> The good news is that if problems are diagnosed early and proper treatment given, a dog with heart disease can live a longer, fuller life.</p>
                                    <p className="SectionText">That's where your vet plays a role. Schedule regular check ups with your vet. If you suspect that your dog has a health issue of any kind, or you notice that your dog hasn't been acting normally, see your local vet.</p>
                                </div>
                                <div className="ReferenceContainer">
                                    <p className="ReferenceText">Reference:</p>
                                    <p className="ReferenceText">1. Atkins C., et al. (2006) Guidelines for the diagnosis and treatment of canine chronic valvular heart disease. <em>J Vet Intern Med.</em>;23(6):1142–1150.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

// export default HeartDiseaseHome;

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(HeartDiseaseHome);
